import React from "react";
import { cn } from "../lib/helpers";

import * as styles from "./hero.module.css";
import * as commonStyles from "../styles/common.module.css";

const maybeImage = (illustration) => {
  let img = null;
  if (illustration && illustration.image && illustration.image.asset && !illustration.disabled) {
    img = (
      <img
        className={cn(
          styles.heroImage,
          commonStyles.fadeIn,
          commonStyles.animationDuration2,
          commonStyles.animationDelay1
        )}
        src={illustration.image.asset.url}
        alt={illustration.image.alt || "Ilustrace"}
      />
    );
  }
  return img;
};

function Hero(props) {
  const img = maybeImage(props.illustration);
  return (
    <div className={cn(styles.container, commonStyles.gradient)}>
      <div
        className={cn(
          styles.leftQuoteWrap,
          commonStyles.animationDuration2,
          commonStyles.animationDelay2,
          commonStyles.fadeInRight
        )}
      >
        <svg
          width="100%"
          className={cn(
            "text-right tracking-wide",
            commonStyles.fontButlerMedium,
            commonStyles.parallax1,
            styles.leftQuote
          )}
          viewBox="0 0 122 18"
        >
          <text x="0" y="15">
            {props.heading1}
          </text>
        </svg>
      </div>
      <div
        className={cn(
          styles.rightQuoteWrap,
          commonStyles.animationDuration2,
          commonStyles.animationDelay3,
          commonStyles.fadeInLeft
        )}
      >
        <svg
          width="100%"
          className={cn(
            "text-right tracking-wide",
            commonStyles.fontButlerMedium,
            commonStyles.parallax3,
            styles.leftQuote
          )}
          viewBox="0 0 95 25"
        >
          <text x="7" y="15">
            {props.heading2}
          </text>
        </svg>
      </div>
      <div className={cn(styles.heroImageWrap, commonStyles.parallax2)}>{img}</div>
    </div>
  );
}

export default Hero;
