import React from "react";
import { cn } from "../lib/helpers";

import { Link } from "gatsby";
import Icon from "./icon/icon";
import BrushImage from "./brushImage";
import LetterText from "./letterText";
import FadeInSection from "./FadeIn";

import * as styles from "./services.module.css";
import * as commonStyles from "../styles/common.module.css";

function ServiceMain(props) {
  const { bodyWest, bodyEast, imageWest, imageEast, servicesWest, servicesEast } = props;

  return (
    <div className={styles.container1}>
      <div className={styles.container2}>
        <FadeInSection>
          <div className={styles.container3}>
            <LetterText blocks={bodyWest} letter="Z" />
            <div className={styles.services}>
              {servicesWest.map(({ service }, indexW) => (
                <Link key={`serviceW_${indexW}`} to={`/${service.slug.current}`}>
                  <h4
                    key={`serviceW_${indexW}`}
                    className={cn(
                      styles.servicesNames,
                      commonStyles.fontReleway,
                      commonStyles.colorAccent
                    )}
                  >
                    <div className={styles.icon}>
                      <Icon type={service.icon} active={true} width={"32px"} />
                    </div>
                    {service.title}
                  </h4>
                </Link>
              ))}
            </div>
          </div>
        </FadeInSection>
        <FadeInSection>
          <div className={styles.image}>
            <BrushImage image={imageWest} />
          </div>
        </FadeInSection>
      </div>

      <div className={cn(styles.container2, styles.reverse)}>
        <FadeInSection>
          <div className={styles.container3}>
            <LetterText blocks={bodyEast} letter="V" />
            <div className={styles.services}>
              {servicesEast.map(({ service: serviceE }, indexE) => (
                <Link key={`serviceE_${indexE}`} to={`/${serviceE.slug.current}`}>
                  <h4
                    className={cn(
                      styles.servicesNames,
                      commonStyles.fontReleway,
                      commonStyles.colorAccent
                    )}
                  >
                    <div className={styles.icon}>
                      <Icon type={serviceE.icon} active={true} width={"32px"} />
                    </div>
                    {serviceE.title}
                  </h4>
                </Link>
              ))}
            </div>
          </div>
        </FadeInSection>
        <FadeInSection>
          <div className={styles.image}>
            <BrushImage image={imageEast} />
          </div>
        </FadeInSection>
      </div>
    </div>
  );
}

export default ServiceMain;
