import React from "react";
import { cn } from "../lib/helpers";

import TextLoop from "react-text-loop";

import * as styles from "./infoRows.module.css";
import * as commonStyles from "../styles/common.module.css";

const InfoRows = (props) => {
  const { rows } = props;
  return (
    <section className={cn(styles.container, "bg-white border-b py-32")}>
      <div className="container px-3 mx-auto flex flex-wrap items-center justify-center">
        <div className={cn("text-left", commonStyles.fontButler, commonStyles.colorDarkGray)}>
          <span className={cn(styles.paragraph)}>{`${props.claim1}`}</span>
          <span className={cn(styles.paragraph2)}>
            {`${props.claim2}`}
            <TextLoop
              interval={4000}
              springConfig={{
                stiffness: 150,
                damping: 35,
              }}
            >
              {rows.map((row, index) => (
                <span key={`favourite_${index}`} className={cn(commonStyles.colorAccent)}>
                  {row}
                </span>
              ))}
            </TextLoop>
          </span>
        </div>
      </div>
    </section>
  );
};

export default InfoRows;
