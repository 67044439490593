import React, { useEffect, useRef, useState } from "react";
import { cn } from "../lib/helpers";

import { Link } from "gatsby";
import Icon from "./icon/icon";
import Arrow from "../images/sipka.png";

import * as styles from "./actions.module.css";
import * as commonStyles from "../styles/common.module.css";

const isBrowser = typeof window !== "undefined";

function Actions({ actionsData = [] }) {
  const ref = useRef();
  const [actionsActive, setActive] = useState([]);

  if (isBrowser) {
    let throttleTimeout = null;

    const callBack = () => {
      if (!ref.current || actionsActive.length > 0) return;

      const bbox = ref.current.getBoundingClientRect();
      if (bbox.y < window.innerHeight + 400) {
        setTimeout(() => {
          setActive([true, false, false]);
        }, 0);
        setTimeout(() => {
          setActive([true, true, false]);
        }, 400);
        setTimeout(() => {
          setActive([true, true, true]);
        }, 800);
      } else {
        throttleTimeout = null;
      }
    };

    useEffect(() => {
      const handleScroll = () => {
        if (throttleTimeout === null) {
          throttleTimeout = setTimeout(callBack, 100);
        }
      };

      window.addEventListener("scroll", handleScroll);

      return () => window.removeEventListener("scroll", handleScroll);
    });
  }

  return (
    <div className={cn(commonStyles.fontButler, commonStyles.colorAccent)}>
      <div ref={ref} className={cn(styles.container)}>
        {actionsData.map(({ action }, index) => (
          <div key={`action-${index}`}>
            <Link to={`/konzultace?${encodeURI(action.title)}`}>
              <div className={styles.singleAction}>
                <Icon type={action.icon} active={actionsActive[index]} width={"64px"} />
                <h3 className={styles.title}>{action.title}</h3>
                <div className={styles.priceBefore}>{action.oldPrice}</div>
                <div className={styles.price}>{action.newPrice}</div>
                {action.addition !== null && (
                  <div
                    className={cn(
                      styles.addition,
                      commonStyles.colorDarkGray,
                      commonStyles.fontReleway
                    )}
                  >
                    {action.addition}
                  </div>
                )}
              </div>
            </Link>
          </div>
        ))}
      </div>
      <Link to={"/akce"}>
        <div className={cn(styles.more, commonStyles.fontReleway)}>
          <img className={cn(styles.arrow)} src={Arrow} alt="Šipka" />
          Více o akcích
        </div>
      </Link>
    </div>
  );
}

export default Actions;
