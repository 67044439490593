import React from "react";
import { cn } from "../lib/helpers";

import QuoteImage from "./quoteImage";
import PortableText from "./portableText";

import * as styles from "./reference.module.css";
import * as commonStyles from "../styles/common.module.css";

let skip = undefined;

const renderRefText = (reference, gapTop) => (
  <div
    className={cn(
      styles.container3,
      commonStyles.colorDarkGray,
      commonStyles.fontButler,
      gapTop && styles.gapTop
    )}
  >
    <PortableText blocks={reference.refContent} />
    <div className={cn(styles.subtitle, commonStyles.colorAccent, commonStyles.fontReleway)}>
      {`${reference.refName} – ${reference.refTitle}`}
    </div>
  </div>
);

function Reference(props) {
  const { allReferences = [] } = props;

  return (
    <div className={styles.container1}>
      <h2 className={cn(styles.mag, commonStyles.colorAccent, commonStyles.fontButlerMedium)}>
        Řekli o nás
      </h2>
      {allReferences.map((reference, index) => {
        if (index === skip) return;

        const hasImage = reference.refImage && reference.refImage.asset;
        const twoInRow =
          !hasImage &&
          !(
            allReferences[index + 1] &&
            allReferences[index + 1].refImage &&
            allReferences[index + 1].refImage.asset
          );

        if (twoInRow) skip = index + 1;

        return (
          <div
            className={cn(styles.container2, twoInRow && styles.noGap, index % 2 && styles.reverse)}
          >
            {renderRefText(reference)}
            {hasImage ? (
              <div className={styles.image}>
                <QuoteImage
                  image={{
                    ...reference.refImage,
                    alt: reference.refName,
                  }}
                />
              </div>
            ) : (
              twoInRow && renderRefText(allReferences[index + 1], true)
            )}
          </div>
        );
      })}
    </div>
  );
}

export default Reference;
