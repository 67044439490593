import React, { useEffect, useRef, useState } from "react";
import { cn } from "../lib/helpers";

import * as styles from "./video.module.css";
import * as commonStyles from "../styles/common.module.css";

const getOpacity = (offset) => {
  if (offset === undefined) return 0;
  const { y, height } = offset;
  if (y < 0) {
    return 0;
  }
  if (y < height / 2) {
    return y / (height / 2);
  } else {
    return 1;
  }
};

const getTranslateX = (offset) => {
  if (offset === undefined) return 0;
  const y = offset.y + offset.height / 3;
  if (y < 0) {
    return 0;
  }
  return y / 3;
};

function Video() {
  const ref = useRef();
  const [offset, setOffset] = useState();

  let throttleTimeout = null;

  const callBack = () => {
    throttleTimeout = null;
    const bbox = ref.current.getBoundingClientRect();
    setOffset({ y: bbox.y - 160, height: bbox.height });
  };

  useEffect(() => {
    const handleScroll = () => {
      if (throttleTimeout === null) {
        throttleTimeout = setTimeout(callBack, 10);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  });

  return (
    <div className={cn(styles.container)}>
      <video
        ref={ref}
        className={styles.video}
        src={"/judit-video.mp4"}
        autoPlay={true}
        muted={true}
        loop={true}
        playsInline={true}
      />
      <div className={styles.cover} style={{ opacity: getOpacity(offset) }} />
      <h1 className={cn(commonStyles.fontButlerMedium, styles.claimWrap)}>
        <div style={{ marginLeft: "5%", transform: `translateX(${getTranslateX(offset)}px)` }}>
          Klinika čínské
        </div>
        <div style={{ marginLeft: "10%", transform: `translateX(${getTranslateX(offset)}px)` }}>
          a estetické medicíny
        </div>
        <div style={{ marginLeft: "15%", transform: `translateX(${getTranslateX(offset)}px)` }}>
          Judity Halvové
        </div>
      </h1>
    </div>
  );
}

export default Video;
